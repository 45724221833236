import { Heading } from "@chakra-ui/react"
import { WindowLocation } from "@reach/router"
import { Link } from "gatsby"
import React from "react"
import Layout from "../components/layout/layout"

export default function OverOns({ location }: { location: WindowLocation }) {
  return (
    <Layout title="Over ons" location={location}>
      <div className="page">
        <Heading>Over ons</Heading> <br />
        <div>
          <p>
            Fairsy (voorheen stalletjeslangsdeweg.nl) wil zowel professionele
            lokale boeren, tuinders, kwekers enzovoorts als particulieren met
            een kraampje bij de weg in kaart brengen. Zo ontdekken kopers verse
            (en vaak voordeligere) producten in de buurt en krijgen de boeren en
            kwekers een faire prijs. Rechtstreeks kopen bij boeren, tuinders en
            particulieren met een stalletje aan de weg proberen wij zo
            eenvoudiger en leuker te maken.&nbsp;
          </p>
          <p>
            <i>
              <br />
              Momenteel bouwen wij steeds verder aan onze website met o.a.
              mogelijkheid voor stalletjes om in te loggen en "vers nieuws" te
              delen.
            </i>
          </p>
          <br />
          <p>
            <b>Het idee ontstond...</b>
          </p>
          <p>
            Het idee van een verzamelplatform voor verse producten uit de buurt
            ontstond in 2020 tijdens de corona crisis. Het is superleuk om te
            zien wat er in de buurt aan verse groentestalletjes, fruitkraampjes
            met seizoensfruit uit eigen moestuinen van mensen, vlees en melk bij
            de boer, kamer en tuinplantjes bij kwekerijen, haardhout,
            zelfgemaakte jam e.d. te krijgen is. Rechtstreeks kopen bij
            stalletjes langs de weg, bij kwekers en boeren geeft een goed
            gevoel. Bovendien steunt met hiermee steun de lokale ondernemer en
            de prijs is vaak ook nog eens heel aantrekkelijk Bovendien is{" "}
            <a href="/aanmelden/">aanmelden</a> 100% gratis.&nbsp;
          </p>
          <br />
          <p>
            <b>Allerlei verse producten uit de buurt</b>
          </p>
          <p>
            Populaire producten op Fairsy zijn aardappelen, scharrel eieren,
            uien, spruiten, Hollandse aardbeien, biologische druiven, honing,
            melk, vlees, tuinplanten, kamerplanten en heel veel meer. Je zult
            verbaasd zijn hoeveel boerderijwinkeltjes, fruitstalletjes en
            kraampjes er in Nederland zijn, ook bij jou in de buurt. Inmiddels
            tonen we meer dan 1200 stuks!
          </p>
          <br />
          <p>
            <b>Zelf afhalen, mooie fietsroute van maken</b>
          </p>
          <p>
            Met onze website willen wij kopers stimuleren een mooie fietsroute
            te maken langs de verschillende stalletjes die overal in het land te
            vinden zijn. En als je op de fiets er op uit gaat heb je ook nog
            eens je sport meteen gehad. Twee vliegen in één klap. Neem wel een
            paar plastic bakjes mee, dat kan handig zijn.
          </p>
          <p>
            <br />
            <b>Contact</b>
          </p>
          <p>
            Bekijk hier onze pagina voor stalletjes langs de weg en
            plattelandwinkels <Link to="/aanmelden/">aanmelden</Link>. Contact
            ons, ook voor een gratis vermelding of wijziging doorgeven:
           fairsy24@gmail.com. 
          </p>
          <p>
            Volg ons op facebook&nbsp;
            <a href="https://www.fairsy.nl/facebook/">
              https://www.facebook.com/Fairsy24
            </a>
          </p>
          <p>Laatste update: {new Date().toLocaleString()}</p>
        </div>
      </div>
    </Layout>
  )
}
